<template>
  <div>
    <CRow class="card-header-title d-flex align-items-center p-3 mx-1">
      <h5 class="text-primary m-1 pt-1">Configurable Fields</h5>
    </CRow>
    <CRow class="d-flex align-items-center p-3 mx-1">
      <CCol>
        <CTabs variant="pills" :active-tab="0">
          <CTab class="pb-2" title="Job">
            <CListGroup>
              <CListGroupItem>
                <CCard>
                  <CCardHeader>
                    <div
                      class="card-header-title d-flex align-items-center justify-content-between"
                    >
                      <h5 class="text-primary">Add/Edit Job</h5>
                      <div class="d-flex justify-content-start">
                        <CButton
                          shape="square"
                          color="primary"
                          class="fs-12"
                          @click="jobCollapse = !jobCollapse"
                          >Config</CButton
                        >
                      </div>
                    </div>
                  </CCardHeader>
                  <CCollapse :show="jobCollapse" class="mt-2">
                    <CCardBody>
                      <div
                        class="card-header-title d-flex align-items-center justify-content-between pb-2"
                        v-if="checkConfigurableFields('job', 'is_gender_required')"
                      >
                        <h5 class="text-primary">Gender Required</h5>
                        <div class="d-flex justify-content-start">
                          <CSwitch
                            name="is_gender_required"
                            color="success"
                            :checked="getConfigurableFields.job.is_gender_required"
                            shape="pill"
                            variant="3d"
                            @change.native="handleChangeCheck('job', 'is_gender_required', $event)"
                          />
                        </div>
                      </div>
                      <div
                        class="card-header-title d-flex align-items-center justify-content-between pb-2"
                        v-if="checkConfigurableFields('job', 'is_language_required')"
                      >
                        <h5 class="text-primary">Language Required</h5>
                        <div class="d-flex justify-content-start">
                          <CSwitch
                            name="is_language_required"
                            color="success"
                            :checked="getConfigurableFields.job.is_language_required"
                            shape="pill"
                            variant="3d"
                            @change.native="handleChangeCheck('job', 'is_language_required', $event)"
                          />
                        </div>
                      </div>
                      <div
                        class="card-header-title d-flex align-items-center justify-content-between pb-2"
                        v-if="checkConfigurableFields('job', 'is_min_yrs_exp_required')"
                      >
                        <h5 class="text-primary">Minimum Years Experience Required</h5>
                        <div class="d-flex justify-content-start">
                          <CSwitch
                            name="is_min_yrs_exp_required"
                            color="success"
                            :checked="getConfigurableFields.job.is_min_yrs_exp_required"
                            shape="pill"
                            variant="3d"
                            @change.native="handleChangeCheck('job', 'is_min_yrs_exp_required', $event)"
                          />
                        </div>
                      </div>
                    </CCardBody>
                  </CCollapse>
                </CCard>
              </CListGroupItem>
            </CListGroup>
            <CRow class="mb-4 pb-4">
              <CCol></CCol>
              <CCol class="col-4 d-flex justify-content-center">
                <CButton
                  class="btn-primary rounded-0 small mr-3 d-flex align-items-center mt-2"
                  @click="handleButtonClick"
                >
                  {{getConfigurableId ? 'Update': 'Save'}}
                </CButton>
              </CCol>
              <CCol></CCol>
            </CRow>
          </CTab>
          <!-- <CTab class="pb-2" title="Registration">
            <CCard
            v-for="(registrationField, index) in getAllRegistrationConfigurableFields" :key="index">
              <CCardHeader>
                <div
                  class="card-header-title d-flex align-items-center justify-content-between"
                >
                  <h5 class="text-primary">{{getCandidateLabel(registrationField['candidate_type_id'])}}</h5>
                  <div class="d-flex justify-content-start">
                    <CButton
                      shape="square"
                      color="primary"
                      class="fs-12"
                      @click="toggleDisplay(registrationField['candidate_type_id'])"
                      >Config</CButton
                    >
                  </div>
                </div>
              </CCardHeader>
              <CCollapse :show="quickRegistration[registrationField['candidate_type_id']]" class="mt-2">
                <CCardBody>
                  <div
                    class="card-header-title d-flex align-items-center justify-content-between pb-2"
                    v-for="(displayKey, labelIndex) in registrationField['keys']" :key="labelIndex"
                  >
                    <h5 class="text-primary text-capitalize">{{displayLabel(displayKey)}}</h5>
                    <div class="d-flex justify-content-start">
                      <CSwitch
                        color="success"
                        :checked="isChecked(registrationField.configuration, displayKey)"
                        shape="pill"
                        variant="3d"
                        @change.native="handleChangeCheckRegistration(index, displayKey, $event)"
                        :disabled="markfForDisabled(registrationField.configuration, displayKey)"
                      />
                    </div>
                  </div>
                </CCardBody>
              </CCollapse>
            </CCard>
            <CRow class="mb-4 pb-4">
              <CCol></CCol>
              <CCol class="col-4 d-flex justify-content-center">
                <CButton
                  class="btn-primary rounded-0 small mr-3 d-flex align-items-center mt-2"
                  @click="handleButtonClickRegistration"
                >Update</CButton>
              </CCol>
              <CCol></CCol>
            </CRow>
          </CTab> -->
        </CTabs>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Vue from "vue";

import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      jobCollapse: false,
      quickRegistration: {},
      candidateTypes: [],
    };
  },
  computed: {
    ...mapGetters([
      "getConfigurableFields",
      "getConfigurableId",
      "getCustomerId",
      "organizationId",
      "getAllRegistrationConfigurableFields",
      ]),
  },
  methods: {
    ...mapActions([
      "createConfigurableFields",
      "updateConfigurableFields",
      "getConfigurableFieldsByOrganisationId",
      "fetchOrganizationId",
      "getConfigurableFieldsForRegistration",
      "genericCandidateType",
      "updateRegistrationFieldsForCandidateTypes"
    ]),
    handleChangeCheck(model, fieldName, $event) {
      const checked = $event.target.checked;
      Vue.set(this.getConfigurableFields[model], fieldName, checked);
    },
    handleChangeCheckRegistration(index, fieldName, $event) {
      let checked = $event.target.checked;
      Vue.set(this.getAllRegistrationConfigurableFields[index]['configuration'], fieldName, checked);
    },
    handleButtonClick() {
      if (this.getConfigurableId) {
        this.updateConfigurableFields(this.getConfigurableId);
      } else {
        this.createConfigurableFields({customer_uid: this.getCustomerId, organisation_uid: this.organizationId[0]?.code});
      }
    },
    handleButtonClickRegistration() {
      this.updateRegistrationFieldsForCandidateTypes();
    },
    checkConfigurableFields(model, field) {
      if (
        this.getConfigurableFields
        ? this.getConfigurableFields[String(model)]
        ? this.getConfigurableFields[String(model)].hasOwnProperty(field) : false
        : false
        ) {
        return this.getConfigurableFields[String(model)][String(field)] ? true : false;
      } else return false;
    },
    getCandidateLabel(type_id) {
      return this.candidateTypes.filter((candidateType) => {
        return candidateType.candidate_type_id === type_id;
      }).map(val => {
        return val ? val.candidate_type: ''
      })[0];
    },
    toggleDisplay(type_id) {
      if (this.quickRegistration[String(type_id)]) {
        this.quickRegistration[String(type_id)] = !this.quickRegistration[String(type_id)];
      } else {
        let payload = {}
        payload[type_id] = true;
        this.quickRegistration = {...this.quickRegistration, ...payload};
      }
    },
    displayLabel(displayString) {
      return displayString.split('_').join(' ');
    },
    isChecked(configuration, displayKey) {
      if (typeof(configuration[String(displayKey)]) == 'boolean') {
        return configuration[String(displayKey)];
      } else return false;
    },
    markfForDisabled(configuration, displayKey) {
      if (typeof(configuration[String(displayKey)]) !== 'boolean') {
        return true;
      } else return false;
    },
  },
  async mounted() {
    await this.fetchOrganizationId({ customer_uid: this.getCustomerId });
    this.getConfigurableFieldsByOrganisationId(this.organizationId[0]?.code);
    // let payload = { customer_uid: this.getCustomerId, organisation_id: this.organizationId[0]?.code }
    // this.getConfigurableFieldsForRegistration(payload);

    // let { data } = await this.genericCandidateType();
    // this.candidateTypes = data;
  },
};
</script>

<style>
</style>